import axios from 'axios'
import { navigate } from 'gatsby'

axios.defaults.withCredentials = true

const DEVELOP = false

const baseUrl = DEVELOP ? 'http://localhost:9000' : ''

class Api {
  constructor() {
    this.client = axios.create()
    this.token = null
    this.refreshRequest = null

    this.client.interceptors.request.use(
      config => {
        if (!this.token) {
          return config
        }

        const newConfig = {
          headers: {},
          ...config,
        }

        newConfig.headers.Authorization = `Bearer ${this.token}`
        return newConfig
      },
      e => Promise.reject(e)
    )

    this.client.interceptors.response.use(
      r => r,
      async error => {
        if (
          (error.response && error.response.status !== 401) ||
          error.config.retry
        ) {
          throw error
        }

        if (!this.refreshRequest) {
          this.refreshRequest = this.client.post(`${baseUrl}/api/auth/refresh`)
        }
        const { data } = await this.refreshRequest
        this.refreshRequest = null
        this.token = data.token
        const newRequest = {
          ...error.config,
          retry: true,
        }

        return this.client(newRequest)
      }
    )
  }

  async login({ login, password }) {
    const { data } = await this.client.post(`${baseUrl}/api/auth/login`, {
      login,
      password,
    })
    if (data.token) {
      this.token = data.token
      navigate('/app')
    }
  }

  async logout() {
    const { data } = await this.client.post(`${baseUrl}/api/auth/logout`)
    if (data.success) {
      this.token = null
      navigate('/')
    }
  }

  async checkSession() {
    const { data } = await this.client.post(`${baseUrl}/api/auth/refresh`)
    if (data.token) {
      this.token = data.token
      navigate('/app')
    }
  }

  getUser() {
    return this.client.get(`${baseUrl}/api/crm/user`)
  }

  getTrips(startWeek, endWeek) {
    return this.client.get(`${baseUrl}/api/crm/trips`, {
      params: {
        startWeek,
        endWeek,
      },
    })
  }

  getRoutes(startWeek, endWeek) {
    return this.client.get(`${baseUrl}/api/crm/routes`, {
      params: {
        startWeek,
        endWeek,
      },
    })
  }

  getFinances(startDate, endDate) {
    return this.client.get(`${baseUrl}/api/board/finances`, {
      params: {
        startDate,
        endDate,
      },
    })
  }

  updateFinances(data) {
    return this.client.post(`${baseUrl}/api/board/updateFinances`, data)
  }

  addFinances(data) {
    return this.client.post(`${baseUrl}/api/board/finances`, data)
  }

  calculateFinances(startDate, endDate) {
    return this.client.get(`${baseUrl}/api/board/calculateFinances`, {
      params: {
        startDate,
        endDate,
      },
    })
  }

  getPackages(startDate, endDate) {
    return this.client.get(`${baseUrl}/api/board/packages`, {
      params: {
        startDate,
        endDate,
      },
    })
  }

  addPackages(data) {
    return this.client.post(`${baseUrl}/api/board/packages`, data)
  }

  getMessages(startDate, endDate) {
    return this.client.get(`${baseUrl}/api/board/messages`, {
      params: {
        startDate,
        endDate,
      },
    })
  }

  getFeedback(startDate, endDate) {
    return this.client.get(`${baseUrl}/api/board/feedback`, {
      params: {
        startDate,
        endDate,
      },
    })
  }

  updateFeedback(data) {
    return this.client.post(`${baseUrl}/api/board/updateFeedback`, data)
  }

  getDriverRating(startDate, endDate) {
    return this.client.get(`${baseUrl}/api/board/drivers-rating`, {
      params: {
        startDate,
        endDate,
      },
    })
  }

  getLogs(startDate, endDate) {
    return this.client.get(`${baseUrl}/api/board/logs`, {
      params: {
        startDate,
        endDate,
      },
    })
  }

  updateFinanceRow(routeId) {
    return this.client.post(`${baseUrl}/api/board/update-finance-row`, {
      routeId,
    })
  }

  updatePackageRow(routeId, packageId) {
    return this.client.post(`${baseUrl}/api/board/update-package-row`, {
      routeId,
      packageId,
    })
  }

  updatePackage(data) {
    return this.client.post(`${baseUrl}/api/board/update-package`, data)
  }
}

const api = new Api()

export default api
